import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '../../containers/StoreLocator/StoreItem';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { setActiveMarker, removeActiveMarker } from '../../state/reducers/map';

const Tooltip = styled.div`
  position: absolute;
  width: 230px;
  background-color: #fff;
  transform: translateX(-50%);
  left: 50%;
  bottom: 180%;
  padding: 20px;
  border-radius: 15px;
  display: none;
`;

const Container = styled.div`
  position: relative;
  width: 15px;
  z-index: 5;

  &.active {
    z-index: 10;

    ${Tooltip} {
      display: block;
    }
  }
`;

const CloseBtn = styled.div`
  position: absolute;
  top: 7px;
  right: 10px;
  width: 15px;
  cursor: pointer;

  img {
    max-width: 100%;
  }
`;

const MapMaker = ({ info, num }) => {
  const { t } = useTranslation();
  const { name, address, tel, fax, openHour, lat, lng } = info;
  const { activeMarkers } = useSelector((state) => state.map);
  const dispatch = useDispatch();

  return (
    <Container className={activeMarkers === num ? 'active' : ''}>
      <Icon
        onClick={() =>
          dispatch(setActiveMarker({ num: num, center: { lat, lng } }))
        }
      >
        <span>{num}</span>
      </Icon>
      <Tooltip>
        <div className="font-weight-bold mb-2">{t(name)}</div>
        <div>{t(address)}</div>
        <div>
          {t('tel')}: {tel}
        </div>
        <div>
          {t('fax')}: {fax}
        </div>
        <div
          dangerouslySetInnerHTML={{ __html: `${t('time')}: ${openHour}` }}
        />
        <CloseBtn onClick={() => dispatch(removeActiveMarker())}>
          <img src="data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224px%22%20height%3D%2224px%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22%23000000%22%3E%0A%20%20%20%20%3Cpath%20d%3D%22M19%206.41L17.59%205%2012%2010.59%206.41%205%205%206.41%2010.59%2012%205%2017.59%206.41%2019%2012%2013.41%2017.59%2019%2019%2017.59%2013.41%2012z%22%2F%3E%0A%20%20%20%20%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22%2F%3E%0A%3C%2Fsvg%3E%0A" />
        </CloseBtn>
      </Tooltip>
    </Container>
  );
};

export default MapMaker;
