import React from 'react';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';
import IconImage from '../../assets/images/storelocator/pin.png';
import IconMenImage from '../../assets/images/storelocator/pin_red.png';

const Container = styled.div`
  //cursor: pointer;
  pointer-events:none;
`;

export const Icon = styled.div`
  position: relative;
  color: #ffffff;
  max-width: 15px;
  flex: 0 0 15px;
  height: 18px;
  background: ${(props) =>
    props.theme.name === 'men' ? `url(${IconMenImage})` : `url(${IconImage})`};
  font-size: 11px;

  span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    text-align: center;
  }
`;

const TextWrapper = styled.div`
  font-size: 12px;
  color: ${(props) => props.theme.labelColor};
  padding-left: 5px;
`;

const Name = styled.p``;
const Address = styled.p``;

const StoreItem = ({ num, name, address, onClick }) => {
  const { t } = useTranslation();
  return (
    <Container className="row no-gutters flex-nowrap" onClick={onClick}>
      <Icon>
        <span>{num}</span>
      </Icon>
      <TextWrapper>
        <Name dangerouslySetInnerHTML={{ __html: t(name) }}></Name>
        <Address>{t(address)}</Address>
      </TextWrapper>
    </Container>
  );
};

export default StoreItem;
